import VueToasted, { Toasted } from 'vue-toasted'
import { inject } from 'vue'

export const $toasted = Symbol('$toasted')

// This custom configuration extends the Vue prototype and injects the vue-toast plugin so we can use it globally in Vue 3
// This function will except the current app instance
// This makes $toasted available inside any component template in the application, and also on 'this' of any component instance

export const injectToasted = (app) => {
  const mockVue = Object.create({
    use(plugin, args) {
      plugin.install(this, args)
    },
    component: app.component
  })

  mockVue.prototype = {}
  mockVue.use(VueToasted, { duration: 5000, className: 'nr-toast' })
  Object.assign(app.config.globalProperties, mockVue.prototype)

  // Components using setup can use useToasted instead of this.$toasted
  app.provide($toasted, app.config.globalProperties.$toasted)
}

export const useToasted = (): Toasted => {
  const toasted = inject<Toasted>($toasted)

  if (!toasted) {
    throw new Error('Toast plugin not found')
  }

  return toasted
}
