
import { PropType } from 'vue'
import { truncate, throttle, uniqBy, isEmpty } from 'lodash'
import { mapActions, mapMutations } from 'vuex'
import GlobalFullTextSearch from './global_full_text_search.vue'
import NrModal from '@nextrequestco/components/src/components/NrModal/nr-modal.vue'
import NrButton from '@nextrequestco/components/src/components/NrButton/nr-button.vue'
import NrIcon from '@nextrequestco/components/src/components/NrIcon/NrIcon.vue'
import NrTooltip from '@nextrequestco/components/src/components/NrTooltip/NrTooltip.vue'
import NrSelect from '@nextrequestco/components/src/components/NrSelect/nr-select.vue'
import API from '../api.js'
import consumer from '../../utils/action_cable_consumer.js'
import helpers from '../../utils/helpers.js'

const genericUsers = [
  {
    value: 'admin',
    label: 'Generic Admin',
    role: 'admin'
  },
  {
    value: 'publisher',
    label: 'Generic Publisher',
    role: 'publisher'
  },
  {
    value: 'staff',
    label: 'Generic Staff',
    role: 'staff'
  },
  {
    value: 'guest',
    label: 'Generic Requester',
    role: 'guest'
  }
]

type Account = {
  id: number
  name: string
  document_review: boolean // eslint-disable-line camelcase
  payments: boolean
  redaction: boolean
  uploaders_feature_flag: boolean // eslint-disable-line camelcase
  full_text_search_feature_flag: boolean // eslint-disable-line camelcase
  civicplus_sso_feature_flag: boolean // eslint-disable-line camelcase
  invoicing_feature_flag: boolean // eslint-disable-line camelcase
}

type User = {
  id: number
  email_or_name: string // eslint-disable-line camelcase
}

export default {
  name: 'app-header',
  components: {
    GlobalFullTextSearch,
    NrModal,
    NrButton,
    NrIcon,
    NrTooltip,
    NrSelect
  },
  props: {
    account: {
      type: Object as PropType<Account | null>,
      default() {
        return {
          id: -1,
          name: 'NextRequest',
          document_review: false,
          payments: false,
          redaction: false,
          uploaders_feature_flag: false,
          full_text_search_feature_flag: false,
          civicplus_sso_feature_flag: false,
          invoicing_feature_flag: false
        }
      }
    },
    user: {
      type: Object as PropType<User | null>,
      default: null
    },
    canManageInternalAdminTools: {
      type: Boolean,
      default: false
    },
    canReadStaffOnly: {
      type: Boolean,
      default: false
    },
    canManageAll: {
      type: Boolean,
      default: false
    },
    canReadAdminOnly: {
      type: Boolean,
      default: false
    },
    canPreviewUser: {
      type: Boolean,
      default: false
    },
    permissionsPreviewUser: {
      type: String,
      default: null
    },
    requestPrettyId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isMenuToggled: false,
      permissionModal: false,
      selectedPreviewUsers: [],
      filteredPreviewUsers: [],
      sessionExpired: false,
      previewFetchState: {
        page: 0,
        search_text: '',
        end: false,
        fetching: false
      },
      showSessionWarningModal: false,
      expirationInMinsAndSecs: '',
      csrfToken: null,
      subscription: null
    }
  },

  mounted() {
    this.setUpSessionExpiration()
    this.setCsrfTokenFromPage()
    this.subscribeToUserJobChannel()
  },

  beforeUnmount() {
    this.unsubscribeToUserJobChannel()
  },

  watch: {
    requestPrettyId(newVal, _oldVal) {
      // Catch up job statuses where request pretty id is required
      if (!newVal) return
      this.catchUpOnJobStatuses()
    }
  },

  computed: {
    isRapidReview() {
      return this.$route.name === 'rapid-review'
    },

    isSpa(): boolean {
      return !!this.$router.options.routes.length
    },

    accountSettingsPath() {
      return `/admin/accounts/${this.account.id}`
    },

    userSettingsPath() {
      return `/settings?id=${this.user.id}`
    },

    accountName(): string {
      if (this.account) {
        return truncate(this.account.name, { length: 40 })
      } else {
        return 'NextRequest'
      }
    },

    isAuthenticated(): boolean {
      return !!this.user && !!this.account
    },

    hasFullTextFeature(): boolean {
      return this.isAuthenticated && this.account.full_text_search_feature_flag
    },

    selectedPreviewUserId(): number | null {
      return this.selectedPreviewUsers ? this.selectedPreviewUsers.value : null
    },

    hasCivicplusSSOFeature(): boolean {
      return this.account.civicplus_sso_feature_flag
    },

    csrfParam(): string {
      const csrfEl = document.getElementsByName('csrf-param')
      const param =
        csrfEl.length > 0 ? (csrfEl[0] as HTMLMetaElement).content : ``
      return param
    }
  },

  methods: {
    ...mapActions('rapid_review', [
      'fetchZipFileToDownload',
      'fetchFolders',
      'refreshDocumentReviewList',
      'fetchReviewStateCounts',
      'removeProcessingDocIds'
    ]),

    catchUpOnJobStatuses() {
      const processingJobs = helpers.fetchUserProcessingJobIds(
        this.user.id,
        this.requestPrettyId,
        'rapid_review'
      )
      if (processingJobs && processingJobs.length) {
        this.checkJobStatuses(processingJobs)
      }
    },

    unsubscribeToUserJobChannel() {
      if (consumer.connection.isOpen()) {
        consumer.subscriptions.remove(this.subscription)
      }
    },

    checkJobStatuses(processingJobs) {
      API.sidekiqStatuses({ jids: processingJobs }).then((response) => {
        const jobs = response.data.jobs
        if (jobs && jobs.length) {
          jobs.forEach((job) => {
            const pendingJob = helpers.fetchPendingJobById(
              job.jid,
              this.user.id,
              this.requestPrettyId,
              'rapid_review'
            )
            if (!isEmpty(pendingJob)) {
              const data = {
                status: job.status,
                jobType: pendingJob.jobType,
                jobId: job.jid,
                docIds: pendingJob.docIds,
                prettyId: this.requestPrettyId
              }
              this.setupToastMessage(data)
            }
          })
        }
      })
    },

    subscribeToUserJobChannel() {
      if (!this.user) return
      const self = this
      this.subscription = consumer.subscriptions.create(
        // Subscribe to channel
        {
          channel: 'UserJobChannel',
          user_id: this.user.id
        },
        {
          received(data) {
            const jobData = {
              status: data.status,
              jobType: data.job_type,
              jobId: data.job_id,
              docIds: data.doc_ids,
              prettyId: data.pretty_id || self.requestPrettyId
            }
            self.setupToastMessage(jobData)
          }
        }
      )
    },

    setupToastMessage({ status, jobType, jobId, docIds, prettyId }) {
      if (helpers.backgroundJobComplete(status)) {
        if (jobType === 'bulk_download') {
          this.setupBulkDownload(jobId, docIds?.length, prettyId)
        } else {
          this.setSuccessToastMessage(jobType, docIds?.length)
        }
      } else if (helpers.backgroundJobFailed(status)) {
        this.setFailedToastMessage(jobType, docIds?.length)
      }

      if (
        helpers.backgroundJobComplete(status) ||
        helpers.backgroundJobFailed(status)
      ) {
        if (this.isRapidReview) {
          this.fetchFolders(prettyId).then(() => {
            this.refreshDocumentReviewList()
            this.fetchReviewStateCounts(prettyId)
          })
        }
        if (this.$store) {
          this.removeProcessingDocIds(docIds)
        }
        helpers.removePendingJobById(
          jobId,
          this.user.id,
          prettyId,
          'rapid_review'
        )
      }
    },

    completeToastMessages(numberOfDocuments) {
      return {
        zipfile_creator: `Successfully zipped ${numberOfDocuments} document(s).`,
        process_folders: `Successfully moved ${numberOfDocuments} document(s).`,
        draft_redaction: `Successfully draft redacted ${numberOfDocuments} document(s).`,
        zipfile_reader: `Successfully extracted ${numberOfDocuments} document(s).`,
        bulk_download: `Downloading zip file containing ${numberOfDocuments} document(s).`,
        bulk_delete: `Successfully deleted ${numberOfDocuments} document(s).`,
        mail_reader: `Succesfully extracted ${numberOfDocuments} document.`
      }
    },

    failedToastMessages(numberOfDocuments) {
      return {
        zipfile_creator: `${numberOfDocuments} document(s) failed to zip.`,
        process_folders: `${numberOfDocuments} document(s) failed to move.`,
        draft_redaction: `${numberOfDocuments} document(s) failed to draft redact.`,
        zipfile_reader: `${numberOfDocuments} document(s) failed to unzip.`,
        bulk_download: `Zip file containing ${numberOfDocuments} document(s) failed to download.`,
        bulk_delete: `${numberOfDocuments} document(s) failed to delete.`,
        mail_reader: `${numberOfDocuments} document failed to extract.`
      }
    },

    setSuccessToastMessage(jobType, numberOfDocuments) {
      if (
        !Object.keys(this.completeToastMessages(numberOfDocuments)).includes(
          jobType
        )
      )
        return
      this.$toasted.success(
        this.completeToastMessages(numberOfDocuments)[jobType]
      )
    },

    setFailedToastMessage(jobType, numberOfDocuments) {
      if (
        !Object.keys(this.failedToastMessages(numberOfDocuments)).includes(
          jobType
        )
      )
        return
      this.$toasted.error(this.failedToastMessages(numberOfDocuments)[jobType])
    },

    fetchZipFile(payload, numOfDocs) {
      this.fetchZipFileToDownload(payload)
        .then(() => {
          const message = this.completeToastMessages(numOfDocs).bulk_download
          this.$toasted.success(message)
        })
        .catch(() => {
          const message = this.failedToastMessages(numOfDocs).bulk_download
          this.$toasted.error(message)
        })
    },

    setupBulkDownload(jobId, numOfDocs, prettyId) {
      // Since there is no Vuex store available on the app_header.ts instance, we emit an event to prepare the zip file in app_header.ts when on the non Vue app
      const ssJobId = sessionStorage.getItem(`job_${jobId}`)
      if (ssJobId && jobId === ssJobId) {
        const payload = { jid: jobId, request_id: prettyId }
        this.$store
          ? this.fetchZipFile(payload, numOfDocs)
          : this.$emit('fetch-zip-file-to-download', {
              jid: jobId,
              request_id: prettyId,
              numOfDocs: numOfDocs
            })

        helpers.clearJobIdFromSessionStorage(jobId)
      }
    },
    // @ts-ignore: NEX-9140
    setCsrfTokenFromPage(): string {
      const csrfEl = document.getElementsByName('csrf-token')
      const token =
        csrfEl.length > 0 ? (csrfEl[0] as HTMLMetaElement).content : ``
      this.csrfToken = token
    },
    // @ts-ignore: NEX-9140
    setCsrfTokenFromApi(): string {
      API.getCsrfToken().then((response) => {
        this.csrfToken = response.data.csrf_token
      })
    },
    resetSessionTimeout() {
      API.resetSession().then(() => {
        this.showSessionWarningModal = false
      })
    },
    timeToSessionExpiration() {
      let expirationTime = this.$cookies.get('nextrequest_session_expires')
      const now = new Date()
      expirationTime = new Date(expirationTime)
      // @ts-ignore: NEX-9140
      const differenceTime = new Date(Math.abs(now - expirationTime))
      const minutes = differenceTime.getMinutes()
      const seconds = differenceTime.getSeconds()
      return { minutes: minutes, seconds: seconds }
    },
    setUpSessionExpiration() {
      const sessionCookiePresent = () =>
        this.$cookies?.isKey('nextrequest_session_expires')

      // If the cookie is not set then the session has no expiration, and so we do not need to check for it.
      if (sessionCookiePresent()) {
        const sessionExpirationInterval = setInterval(() => {
          if (this.user && !sessionCookiePresent()) {
            this.sessionExpired = true
            this.showSessionWarningModal = false
            this.setCsrfTokenFromApi()
            localStorage.clear()
            clearInterval(sessionExpirationInterval)
          } else if (this.user && sessionCookiePresent()) {
            const time = this.timeToSessionExpiration()
            if (time.minutes < 2) {
              const formatSeconds = time.seconds.toString().padStart(2, '0')
              this.expirationInMinsAndSecs = `${time.minutes} min ${formatSeconds} sec`
              this.showSessionWarningModal = true
            } else {
              this.showSessionWarningModal = false
            }
          }
        }, 1000)
      }
    },
    directToSignIn() {
      if (this.hasCivicplusSSOFeature) {
        this.$refs.signInForm.requestSubmit()
      } else {
        window.location.href = '/users/sign_in'
      }
    },
    handleNavigate(event, routerNavFunc) {
      if (this.isSpa) {
        return routerNavFunc(event)
      }
    },
    getNavbarItemClassName(path) {
      const isActive = window.location.pathname === path.path
      return ['navbar-item', { 'navbar-item--active': isActive }]
    },
    ...mapMutations(['setAbility']),
    submitPermission(): void {
      if (this.isRapidReview && this.selectedPreviewUsers.role === 'guest') {
        this.$toasted.error(
          'The user you have selected does not have access to this page'
        )
        return
      }
      API.setPermissionUserPreview({
        user_id: this.selectedPreviewUserId
      }).then((_response) => {
        window.location.reload()
      })
    },
    setPermissionModal(): void {
      this.permissionModal = true
    },
    searchPreviewUsers: throttle(function (searchText = '') {
      this.fetchPreviewUsers(searchText)
    }, 400),

    setPreviewUsers(staff: object): void {
      this.selectedPreviewUsers = staff
    },
    fetchPreviewUsers(searchText: string = ''): void {
      const newFetchState = { ...this.previewFetchState }
      if (
        this.previewFetchState.search_text === searchText && // eslint-disable-line camelcase
        this.previewFetchState.end
      ) {
        return
      }
      if (this.previewFetchState.search_text === searchText) {
        Object.assign(newFetchState, {
          search_text: searchText,
          page: this.previewFetchState.page + 1,
          fetching: true
        })
      }
      // eslint-disable-next-line camelcase
      if (this.previewFetchState.search_text !== searchText) {
        Object.assign(newFetchState, {
          search_text: searchText,
          page: 1,
          end: false,
          fetching: true
        })
      }
      const payload = {
        search_text: searchText,
        page_number: newFetchState.page,
        request_pretty_id: this.requestPrettyId
      }
      API.fetchPreviewUsers(payload)
        .then((response) => {
          const res = response.data
          let staffList = []
          if (res.length) {
            staffList = res.map((usr) => {
              return {
                value: usr.id,
                label: usr.name_with_email,
                role: usr.role
              }
            })
          }
          if (this.previewFetchState.search_text !== payload.search_text) {
            this.filteredPreviewUsers = []
            this.filteredPreviewUsers = staffList.concat(genericUsers)
          } else {
            this.filteredPreviewUsers = uniqBy(
              [...this.filteredPreviewUsers, ...staffList, ...genericUsers],
              'value'
            )
          }
          this.previewFetchState = Object.assign(newFetchState, {
            end: staffList.length < 25,
            fetching: false
          })
        })
        .catch(() => {
          this.filteredPreviewUsers = [].concat(genericUsers)
          this.previewFetchState = Object.assign(newFetchState, {
            fetching: false
          })
        })
    },
    loadMorePreviewUsers: throttle(function () {
      this.fetchPreviewUsers(this.previewFetchState.search_text)
    }, 400),

    resetPreviewModal() {
      this.filteredPreviewUsers = []
      this.permissionModal = false
      this.previewFetchState = {
        page: 0,
        search_text: '',
        end: false,
        fetching: false
      }
    },

    handleToggleMenu(): void {
      this.isMenuToggled = !this.isMenuToggled

      if (this.isMenuToggled) {
        this.$refs.menu.classList.add('is-active')
      } else {
        this.$refs.menu.classList.remove('is-active')
      }
    },

    handleSignoutClick(): void {
      this.$emit('on-sign-out')
    }
  }
}
