import '../src/polyfills.js'
import axios from 'axios'
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import VueCookies from 'vue3-cookies'
import AppHeader from '../src/vue_shared/components/app_header.vue'
import signOut from '../src/utils/sign_out'
import API from '../src/vue_shared/api.js'
import { injectToasted } from '../src/utils/vue-toasted/inject_toasted'
import helpers from '../src/utils/helpers.js'

document.addEventListener('DOMContentLoaded', () => {
  const csrfEl = document.getElementsByName('csrf-token')
  const token = csrfEl.length > 0 ? csrfEl[0].getAttribute('content') : ``
  axios.defaults.headers['X-CSRF-Token'] = token
  axios.defaults.headers.Accept = 'application/json'

  const headerEl = document.querySelector(
    '#app-header-legacy .mount'
  ) as HTMLElement

  if (!headerEl) {
    return
  }

  let permissions = {
    canManageInternalAdminTools: false,
    canReadAdminOnly: false,
    canReadStaffOnly: false,
    canManageAll: false,
    canPreviewUser: false,
    permissionsPreviewUser: ''
  }
  let user = null
  let account = null
  let requestPrettyId = null

  try {
    requestPrettyId = headerEl.dataset.requestprettyid
  } catch (error) {}
  try {
    permissions = JSON.parse(headerEl.dataset.permissions)
  } catch (error) {}
  try {
    user = JSON.parse(headerEl.dataset.user)
  } catch (error) {}
  try {
    account = JSON.parse(headerEl.dataset.account)
  } catch (error) {}

  const app = createApp(AppHeader, {
    canManageInternalAdminTools: permissions.canManageInternalAdminTools,
    canReadAdminOnly: permissions.canReadAdminOnly,
    canReadStaffOnly: permissions.canReadStaffOnly,
    canManageAll: permissions.canManageAll,
    canPreviewUser: permissions.canPreviewUser,
    permissionsPreviewUser: permissions.permissionsPreviewUser,
    requestPrettyId: requestPrettyId,
    user,
    account,
    onOnSignOut: () => {
      signOut(account)
    },
    // Fetches zip file when on non Vue app
    // TODO: Use vuex action instead after moving non Vue pages into Vue app.
    onFetchZipFileToDownload: (args) => {
      const payload = { jid: args.jid, request_id: args.request_id }
      return API.fetchDocFiles(payload)
        .then((response) => {
          const link = document.createElement('a')
          link.href = response.data.url
          link.setAttribute('download', response.data.filename)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          if (response.data.bulk) {
            helpers.removePendingJobFromLocalStorage(
              `${user.id}${payload.request_id}zipFiles`,
              payload.jid
            )
          }
          appToasted().success(
            `Downloading zipped file containing ${args.numOfDocs} document(s).`
          )
        })
        .catch(() => {
          appToasted().error(
            `Zipped file containing ${args.numOfDocs} document(s) failed to download.`
          )
          helpers.removePendingJobFromLocalStorage(
            `${user.id}${payload.request_id}zipFiles`,
            payload.jid
          )
        })
    }
  })

  const appToasted = () => {
    return app.config.globalProperties.$toasted
  }

  const router = createRouter({
    history: createWebHistory(),
    routes: []
  })

  app.use(router)
  app.use(VueCookies)
  injectToasted(app)
  app.mount(headerEl)
})
